.page-header, .page-header-space {
  height: 185px;
}

.page-footer, .page-footer-space {
  height: 96px;
}

.page-footer {
  position: fixed;
  bottom: 0;
  width: 100%;
  //border-top: 1px solid black; /* for demo */
  //background: yellow; /* for demo */
}

.page-header {
  position: fixed;
  top: 0mm;
  width: 100%;
  //border-bottom: 1px solid black; /* for demo */
  //background: yellow; /* for demo */
}

/*
.page {
  page-break-after: always;
}
*/

@page {
  size: A4;
}

@media print {
  thead {display: table-header-group;} 
  tfoot {display: table-footer-group;}
  
  button {display: none;}
  
  body {margin: 0;}
}